import { createModel } from "@rematch/core";
import ky from "ky";
import { recipeTypeId } from "../util";
import { RootModel } from "./models";

const LocalStorage = {
    RecipeTypePrefix: "recipeType:",
    IngredientPriorityPrefix: "ingredientPriority:",
    RecipeListKey: "recipes"
}

export interface RecipeData {
    name: string;
    id: number;
    icon: string;
    craft: {
        id: number;
        job: number;
        rlvl: number;
        durability: number;
        quality: number;
        progress: number;
        lvl: number;
        stars: number;
        ingredients: {
            id: number;
            amount: number;
            quality?: number;
            useHqCount?: number;
        }[]
    }[];

    count: number;
}

export interface Ingredient {
    id: number;
    name: string;
    icon: string;
    ilvl: number | null;

    allowHq: boolean;
    needed: number;
    hqNeeded: number;
    priority: number;
}

export interface RecipeType {
    rlvl: number;
    durability: number;

    missingQuality: number;
}

interface RecipeState {
    recipes: {[id: number]: RecipeData};
    ingredients: {[id: number]: Ingredient};
    recipeTypes: {[id: string]: RecipeType};

    needsUpdate: boolean;
}

export const recipe = createModel<RootModel>()({
    state: {
        recipes: {},
        ingredients: {},
        recipeTypes: {},
        needsUpdate: false
    } as RecipeState,
    reducers: {
        addRecipe: (state, recipe: RecipeData) => {
            return {
                ...state,
                recipes: {
                    ...state.recipes,
                    [recipe.id]: recipe
                },
                needsUpdate: true
            };
        },
        addIngredient: (state, ingredient: Ingredient) => {
            return {
                ...state,
                ingredients: {
                    ...state.ingredients,
                    [ingredient.id]: ingredient
                },
                needsUpdate: true
            };
        },
        setIngredientPriority: (state, payload: {id: number, priority: number}) => {
            localStorage.setItem(`${LocalStorage.IngredientPriorityPrefix}${payload.id}`, payload.priority.toFixed(0));
            return {
                ...state,
                ingredients: {
                    ...state.ingredients,
                    [payload.id]: {
                        ...state.ingredients[payload.id],
                        priority: payload.priority
                    }
                }
            };
        },

        setRecipeCount: (state, payload: {recipeId: number, count: number}) => {
            const newState = {
                ...state,
                recipes: {
                    ...state.recipes
                },
                ingredients: {
                    ...state.ingredients
                },
                needsUpdate: true
            };
            const diff = payload.count - state.recipes[payload.recipeId].count;

            newState.recipes[payload.recipeId].count = payload.count;
            newState.recipes[payload.recipeId].craft[0].ingredients.forEach(ing => {
                newState.ingredients[ing.id].needed += ing.amount * diff;
            });

            return newState;
        },
        setIngredientNeededCount: (state, payload: {ingId: number, count: number}) => {
            const newState = {
                ...state,
                ingredients: {
                    ...state.ingredients
                },
                needsUpdate: true
            };
            newState.ingredients[payload.ingId].needed = payload.count;
            return newState;
        },

        addRecipeType: (state, payload: {id: string, type: RecipeType}) => {
            const newState = {
                ...state
            };
            newState.recipeTypes[payload.id] = payload.type;
            return newState;
        },
        setRecipeTypeQuality: (state, payload: {id: string, quality: number}) => {
            const newState = {
                ...state,
                recipeTypes: {
                    ...state.recipeTypes
                },
                needsUpdate: true
            };
            newState.recipeTypes[payload.id].missingQuality = payload.quality;

            localStorage.setItem(`${LocalStorage.RecipeTypePrefix}${payload.id}`, payload.quality.toFixed(0));

            return newState;
        },

        calculate: (state, payload: null) => {
            const newState = {
                ...state,
                ingredients: {
                    ...state.ingredients
                },
                recipes: {
                    ...state.recipes
                }
            }

            const hqMats: {[id: number]: number} = {};
            Object.values(newState.recipes).forEach(recipe => {
                const craft = recipe.craft[0];
                const requiredQuality = state.recipeTypes[`${craft.rlvl}:${craft.durability}`].missingQuality;
                let quality = 0;

                const sortedIngredients = craft.ingredients.map((ing, i) => {
                    return {
                        ...ing,
                        index: i,
                        priority: newState.ingredients[ing.id].priority
                    }
                }).sort((ing1, ing2) => {
                    return ing2.priority - ing1.priority
                });

                for (const ing of sortedIngredients) {
                    if (!ing.quality) {
                        continue;
                    }
                    if (quality >= requiredQuality) {
                        break;
                    }

                    for (let i = 0; i < ing.amount && quality < requiredQuality; i++) {
                        craft.ingredients[ing.index].useHqCount = (craft.ingredients[ing.index].useHqCount ?? 0) + 1;
                        quality += ing.quality;
                        if (!(ing.id in hqMats)) {
                            hqMats[ing.id] = 0;
                        }
                        hqMats[ing.id] += recipe.count;
                    }
                }
            });
            Object.entries(hqMats).forEach(([id, count]) => {
                newState.ingredients[Number(id)].hqNeeded = count;
            });

            return newState;
        }
    },
    effects: dispatch => ({
        async addRecipeByItemId(itemId: number, state) {
            if (itemId in Object.keys(state.recipe.recipes)) {
                return;
            }

            const itemData: {item: RecipeData} = await ky.get(`https://garlandtools.org/db/doc/item/en/3/${itemId}.json`).json();

            const craft = itemData.item.craft[0]
            craft.ingredients.forEach(async ing => {
                if (ing.id in state.recipe.ingredients) {
                    dispatch.recipe.setIngredientNeededCount({
                        ingId: ing.id,
                        count: state.recipe.ingredients[ing.id].needed + ing.amount
                    });
                    return;
                }
                const ingData: {item: RecipeData} = await ky.get(`https://garlandtools.org/db/doc/item/en/3/${ing.id}.json`).json();
                dispatch.recipe.addIngredient({
                    id: ingData.item.id,
                    name: ingData.item.name,
                    ilvl: ingData.item.craft ? ingData.item.craft[0].rlvl : null,
                    icon: ingData.item.icon,

                    allowHq: true,
                    hqNeeded: 0,
                    needed: ing.amount,
                    priority: Number(localStorage.getItem(`${LocalStorage.IngredientPriorityPrefix}${ingData.item.id}`))
                });
            });

            itemData.item.count = 1;
            dispatch.recipe.addRecipe(itemData.item);

            const type: RecipeType = {
                rlvl: craft.rlvl,
                durability: craft.durability,
                missingQuality: 0
            }
            const typeId = recipeTypeId(type);
            if (!state.recipe.recipeTypes[typeId]) {
                type.missingQuality = Number(localStorage.getItem(`${LocalStorage.RecipeTypePrefix}${typeId}`) ?? 'NaN') || craft.quality;
                dispatch.recipe.addRecipeType({id: typeId, type});
            }
        }
    })
});