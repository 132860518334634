import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import { Ingredient, RecipeData } from '../models/recipe';
import { IconUrlPrefix } from '../urls';
import { MdStar } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../store';

export interface RecipeCardProps {
    className?: string;
    children?: ReactNode;

    recipe: RecipeData;
    ingredients: {[id: number]: Ingredient}
}

export const RecipeCard = ({ className, children, recipe, ingredients }: RecipeCardProps) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const dispatch: Dispatch = useDispatch();

    const craft = recipe.craft[0];

    return (
        <div className={cn(className, 'flex flex-col p-2 bg-white rounded-lg shadow-md')}>
            <div className="flex flex-row">
                <img alt="icon" src={`${IconUrlPrefix}/${recipe.icon}.png`}
                    className="w-16 h-16 mr-4" />
                <div>
                    <div className="flex items-center">
                        <h3 className="font-bold text-lg mr-2">{recipe.name}</h3>
                        {Array.from(Array(craft.stars), (e, i) => <MdStar key={i} />)}
                    </div>
                    <div className="flex mt-1">
                        <span className="font-semi-bold mr-1">Recipe lvl:</span>
                        <span className="mr-2">{craft.rlvl}</span>

                        <span className="font-semi-bold mr-1">Durability:</span>
                        <span className="mr-2">{craft.durability}</span>
                    </div>
                </div>
                <div className="flex-1"></div>
                <input type="text" name="count" value={recipe.count}
                    onChange={(e) => {dispatch.recipe.setRecipeCount({recipeId: recipe.id, count: Number(e.target.value)})}}
                    className="bg-gray-200 w-8 self-center p-2 mr-4 text-center rounded"/>

                {expanded || <button className="w-8" onClick={() => setExpanded(true)}>V</button>}
                {!expanded || <button className="w-8" onClick={() => setExpanded(false)}>^</button>}
            </div>


            {expanded && (
                <div className="mt-2">
                    <table>
                        <tbody>
                            {recipe.craft[0].ingredients.map(ing => {
                                const ingDesc = ingredients[ing.id];

                                return <tr key={ing.id}>
                                    <td className="pr-4"><img className="w-12 h-12" src={`${IconUrlPrefix}/${ingDesc?.icon}.png`} alt="Icon"/></td>
                                    <td className="pr-4">{ingDesc?.name}</td>
                                    {ing.quality ? <td className="pr-4">
                                        <span className="font-bold">Quality: </span>{ing.quality.toFixed(0)}
                                    </td> : <td className="pr-4"></td>}
                                    <td className="pr-4"><span className="font-bold">HQ: </span>{ing.useHqCount ?? 0}</td>
                                    <td className="pr-4"><span className="font-bold">NQ: </span>{ing.amount - (ing.useHqCount ?? 0)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
