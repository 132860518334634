import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Counter } from './views/Counter';
import { RecipePlanner } from './views/RecipePlanner';

function App() {
  return (
    <div className="App">
      <RecipePlanner />
    </div>
  );
}

export default App;
