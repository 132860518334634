import React, { ReactNode } from 'react';
import cn from 'classnames';
import { RecipeType } from '../models/recipe';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../store';

export interface RecipeTypeCardProps {
    className?: string;
    children?: ReactNode;

    type: RecipeType;
    typeId: string;
}

export const RecipeTypeCard = ({ className, children, type, typeId }: RecipeTypeCardProps) => {
    const dispatch: Dispatch = useDispatch();

    return (
        <div className={cn(className, 'flex flex-row p-2 bg-white rounded-lg shadow-md')}>
            <div className="flex flex-col mr-4">
                <div className="flex flex-row">
                    <span className="font-semi-bold mr-1">Recipe level:</span>
                    <span className="mr-2">{type.rlvl}</span>
                </div>

                <div className="flex flex-row">
                    <span className="font-semi-bold mr-1">Durability: </span>
                    <span className="mr-2">{type.durability}</span>
                </div>
            </div>
            <div className="flex-1"></div>
            <label htmlFor="missingQuality" className="font-bold self-center mr-2">Missing quality: </label>
            <input type="text" name="missingQuality" value={type.missingQuality}
                onChange={(e) => {dispatch.recipe.setRecipeTypeQuality({id: typeId, quality: Number(e.target.value)})}}
                className="bg-gray-200 w-24 self-center p-2 mr-4 text-center rounded" />
        </div>
    )
}
