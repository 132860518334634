import React, { ReactNode } from 'react';
import cn from 'classnames';

export interface InputButtonProps {
    className?: string;
    children?: ReactNode;

    type: 'text'|'number';
    value: string;
    onSubmit: (value: string) => void;
    onChange: (value: string) => void;
}

export const InputButton = ({ className, children, type, value, onSubmit, onChange }: InputButtonProps) => {
    return (
        <div className={cn(className, 'flex shadow rounded-md')}>
            <input value={value} onChange={(e) => onChange(e.target.value)}
                onKeyPress={(e) => {if (e.key === 'Enter') onSubmit(value)}}
                name="recipeName" type={type}
                className="h-10 px-2 flex-1 border border-gray-300 rounded-l-md focus:border-blue-700" />
            <button onClick={(e) => onSubmit(value)}
                className="h-10 px-4 text-white border-l-0 rounded-r-md border-blue-700 bg-blue-700 hover:bg-blue-800
                    focus:ring-2 focus:ring-offset-1 font-bold uppercase text-md focus:outline-none cursor-pointer"
                type="button">{children}</button>
        </div>
    )
}
