import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import ky from 'ky';
import { Ingredient, RecipeData } from '../models/recipe';
import { Dispatch, RootState } from '../store';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { InputButton } from '../components/InputButton';
import { RecipeCard } from '../components/RecipeCard';
import { IconUrlPrefix } from '../urls';
import { RecipeTypeCard } from '../components/RecipeTypeCard';



export interface RecipePlannerProps {
    className?: string;
}

interface GarlandSearchItem {
    type: string;
    id: string;
    obj: {
        i: number;
        n: string;
        l: number;
        c: string;
        t: number;
        g: number;
        j: number;
        r: number;
        e: number;
        f?: {
            id: number;
            job: number;
            lvl: number;
            starts: number;
        }[]
    }
}

export const RecipePlanner = ({ className }: RecipePlannerProps) => {
    const [recipeName, setRecipeName] = useState('');
    const [ingredientSortCol, setIngredientSortCol] = useState<keyof Ingredient>('ilvl');
    const [ingredientSortDir, setIngredientSortDir] = useState('desc');
    const dispatch: Dispatch = useDispatch();
    const recipes = useSelector((state: RootState) => state.recipe.recipes, shallowEqual);
    const ingredients = useSelector((state: RootState) => state.recipe.ingredients);
    const recipeTypes = useSelector((state: RootState) => state.recipe.recipeTypes);
    const needsUpdate = useSelector((state: RootState) => state.recipe.needsUpdate);

    const search = async () => {
        const results: GarlandSearchItem[] = await ky.get('https://garlandtools.org/api/search.php', {
            searchParams: {
                text: recipeName,
                lang: 'en'
            }
        }).json();

        const item = results.find(item => item.obj.f);
        if (!item) {
            return;
        }
        dispatch.recipe.addRecipeByItemId(Number(item.id));
        setRecipeName('');
    };

    const recipeList = Object.entries(recipes).map(([id, recipe]) => (
        <RecipeCard className="mb-4" recipe={recipe} ingredients={ingredients} key={id} />
    ));
    const recipeTypeList = Object.entries(recipeTypes).map(([id, type]) => (
        <RecipeTypeCard className="mb-4" type={type} typeId={id} key={id} />
    ));

    const sortedIngredients = Object.entries(ingredients).sort(([id1, ing1], [id2, ing2]) => {
        const first = ing1[ingredientSortCol];
        const second = ing2[ingredientSortCol];
        if (typeof(first) === 'string' && typeof(second) === 'string') {
            return first.localeCompare(second);
        }
        if ((typeof(first) === 'number' || first === null) && (typeof second === 'number' || second === null)) {
            return ((first ?? 0) - (second ?? 0)) * (ingredientSortDir == 'asc' ? 1 : -1);
        }
        throw new Error('Invalid sort column type');
    });

    return (
        <div className={cn(className, 'container flex flex-col mx-auto pt-12 mb-8')}>
            <div className="pb-8">
                <h1 className="pb-2 text-3xl font-bold text-gray-700">Recipe HQ Optimizer</h1>
                <div className="flex items-center">
                    <label className="pr-3" htmlFor="recipeName">Item name:</label>
                    <InputButton className="w-96" type="text" value={recipeName} onChange={(val) => setRecipeName(val)} onSubmit={(val) => search()}>
                        Add
                    </InputButton>

                    <button onClick={() => {dispatch.recipe.calculate(null)}} disabled={!needsUpdate} className="ml-8 h-10 px-4 disabled:opacity-50 disabled:bg-blue-700 text-white rounded-md bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:ring-offset-1 font-bold uppercase text-md focus:outline-none cursor-pointer">Calculate</button>
                </div>
            </div>

            <div className="flex space-x-8">
                <div className="w-8/12">
                    <h2 className="mb-1 font-bold text-lg">Recipes</h2>
                    {recipeList}

                    <h2 className="mt-4 mb-1 font-bold text-lg">Ingredients</h2>
                    {/* {ingredientList} */}

                    <table className="table-auto border-collapse bg-white shadow-md">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-blue-500 border">Icon</th>
                                <th className="py-2 px-4 border-blue-500 border">Name</th>
                                <th className="py-2 px-4 border-blue-500 border">Recipe level</th>
                                <th className="py-2 px-4 border-blue-500 border">Allow HQ</th>
                                <th className="py-2 px-4 border-blue-500 border">Priority</th>
                                <th className="py-2 px-4 border-blue-500 border">HQ Needed</th>
                                <th className="py-2 px-4 border-blue-500 border">Needed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedIngredients.map(([id, ing]) => (
                                <tr key={id}>
                                    <td className="p-1 border-blue-500 border text-center"><img className="w-16 h-16" src={`${IconUrlPrefix}/${ing.icon}.png`} alt="Icon"/></td>
                                    <td className="p-1 border-blue-500 border text-center">{ing.name}</td>
                                    <td className="p-1 border-blue-500 border text-center">{ing.ilvl}</td>
                                    <td className="p-1 border-blue-500 border text-center">{ing.allowHq}</td>
                                    <td className="p-1 border-blue-500 border text-center">
                                        <input type="text" value={ing.priority}
                                            onChange={(e) => {dispatch.recipe.setIngredientPriority({id: ing.id, priority: Number(e.target.value)})}}
                                            className="bg-gray-200 w-8 self-center p-2 mr-4 text-center rounded" />
                                    </td>
                                    <td className="p-1 border-blue-500 border text-center">{ing.hqNeeded}</td>
                                    <td className="p-1 border-blue-500 border text-center">{ing.needed}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                <div className="w-4/12">
                    <h2 className="mb-1 font-bold text-lg">Recipe types</h2>
                    {recipeTypeList}
                </div>
            </div>
        </div>
    )
}
